<template lang="pug">
.card
  header.card-header
    .card-header-title
      | {{ variable.name }}
    a.card-header-icon(href="#" aria-label='Status' v-if='currentLimit')
      color-indicator(:color='currentLimit.color')
  .card-content
    .mb-3(v-if='currentLimit')
      .variable-text-status(:style='{ color: currentLimit.color }') {{ currentLimit.name }}
    div(v-if='hasValue')
      .variable-value(:style='{ color: currentLimit && currentLimit.color }') {{ variable[`status${currentValue}`] }}
      p {{ variable.currentValue.date | datetime }} - hace {{ variable.currentValue.date | dateDistance }}
    div(v-else) Sin valor
</template>

<script>
import ColorIndicator from '@/components/shared/ColorIndicator'

export default {
  components: { ColorIndicator },
  props: {
    variable: { type: Object }
  },
  computed: {
    min () {
      return 0
    },
    max () {
      return 100
    },
    hasValue () {
      return this.currentValue || this.currentValue === 0
    },
    currentLimit () {
      if (!this.hasValue) return null
      return this.variable.variableLimits.find(limit => limit.value >= this.currentValue)
    },
    currentValue () {
      return this.variable.currentValue && this.variable.currentValue.value
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../scss/ews';
</style>
