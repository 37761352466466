<template lang="pug">
div
  b-loading(:is-full-page='false' v-model='isLoading')
  b-message(v-if='!isLoading && !outputs.length') No hay salidas creadas o activas para esta estación
  .outputs
    .card(v-for='output in outputs' :key='output.id' :class='{ inactive: !output.active }')
      header.card-header
        p.card-header-title  {{ output.name }}
        router-link.card-header-icon(
          aria-label='Configurar'
          :to='{ name: "ews-admin-variable", params: { id: output.id }, query: { onSave: "ews-dashboard" } }'
        )
          icon(icon='cog')
      .card-content
        .field.has-addons
          p.control
            b-button(type='is-primary' :loading='isSaving' @click='open(output)' :disabled='!output.active') {{ output.status1 }}
          p.control
            b-button(:loading='isSaving' @click='close(output)' :disabled='!output.active') {{ output.status0 }}
</template>

<script>
import { mapActions } from 'vuex'
import variablesService from '@/services/variables.service'
import { commandTypeEnum, variableTypeEnum } from '@/constants/enums'
import toastService from '@/services/toast.service'
import commandsService from '@/services/commands.service'

export default {
  props: {
    deviceId: { type: String }
  },
  data () {
    return {
      isLoading: false,
      isSaving: false,
      outputs: []
    }
  },
  async created () {
    this.isLoading = true
    const result = await variablesService.getAll({ deviceId: this.deviceId, typesId: variableTypeEnum.DO })
    if (result) {
      this.outputs = result
    }
    this.isLoading = false
  },
  methods: {
    ...mapActions({
      refreshCommands: 'commands/refreshCommands'
    }),
    async open (output) {
      const name = `${output.status1}: ${output.name}`
      this.sendCommand(output, name, commandTypeEnum.OpenOutput)
    },
    async close (output) {
      const name = `${output.status0}: ${output.name}`
      this.sendCommand(output, name, commandTypeEnum.CloseOutput)
    },
    async sendCommand (output, name, type) {
      this.isSaving = true
      if (!output.number) toastService.error('No se configuró un número de salida para esta variable')
      const command = {
        deviceId: this.deviceId,
        type,
        parameters: `${output.number}`,
        name
      }
      const result = await commandsService.create(command)
      if (result) {
        toastService.show('Se está enviando el comando...')
        this.refreshCommands()
        if (this.$parent && this.$parent.close) {
          this.$parent.close('')
        }
      }
      this.isSaving = false
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~bulma/sass/utilities/initial-variables";

.outputs {
  display: grid;
  column-gap: $gap / 2;
  row-gap: $gap / 2;
  grid-template-columns: repeat(3, 250px);
}

.card.inactive {
  opacity: 0.5;
}
</style>
