<template lang="pug">
.variable-value-indicator
  .limits
    .limit(v-for='limit in limitsWithPerc' :style='{ backgroundColor: limit.color, flex: limit.flex }')
  .value-container
    .value-label-container
      .value-label {{ currentValue | percent }}
    .value-indicator(:style='{ backgroundColor: valueColor, width: `${valueSize}%` }')
</template>

<script>
export default {
  props: {
    variable: { type: Object },
    min: { type: Number },
    max: { type: Number },
    currentLimit: { type: Object }
  },
  computed: {
    currentValue () {
      return this.variable.currentValue && this.variable.currentValue.value
    },
    hasValue () {
      return this.currentValue || this.currentValue === 0
    },
    maxValue () {
      const currentValue = this.currentValue || 0
      return Math.max(this.min, this.max, currentValue)
    },
    limitsWithPerc () {
      let prevValue = this.min
      return this.variable.variableLimits.map(limit => {
        const range = this.max - this.min
        const val = limit.value - this.min
        const final = (val / range) * 100
        limit.flex = final - prevValue
        prevValue = final
        return limit
      })
    },
    valueSize () {
      return this.hasValue && (this.currentValue / this.maxValue) * 100
    },
    valueColor () {
      return this.currentLimit && this.currentLimit.color
    }
  }
}
</script>

<style lang="scss" scoped>
.variable-value-indicator:hover {
  .limit {
    opacity: 1;
  }
}

.limits {
  display: flex;
}

.limit {
  height: 8px;
  border-radius: 3px;
  opacity: 0.7;
}

.value-indicator {
  border-radius: 3px;
  margin-top: 2px;
}

.value-container {
  position: relative;
}

.value-indicator,
.value-label-container {
  height: 60px;
}

.value-label-container {
  position: absolute;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.value-label {
  background: rgba(255,255,255,0.7);
  font-size: 2em;
  font-weight: bold;
  padding: 0px 5px;
  border-radius: 5px;
  color: black;
}
</style>
